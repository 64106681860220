<template>
  <div>
    <v-timeline align-top dense>
      <v-timeline-item
        v-for="task in userTasks"
        :key="task.taskId"
        color="undefined"
      >
        <template v-slot:icon>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-avatar v-on="on">
                <v-icon
                  @click="onTaskSelected(task)"
                  @contextmenu="openContextMenu($event, task)"
                  :color="getStatusColorClass(task)"
                  >{{ iconTask }}</v-icon
                >
              </v-avatar>
            </template>
            <span>{{ taskStatusTooltip(task) }}</span>
          </v-tooltip>
        </template>
        <v-row dense>
          <!-- Task Status Info -->
          <v-col cols="12" md="5">
            <div :class="taskStatusTextClass(task)">
              {{ getTaskStatus(task) }}
            </div>
            <div :class="columnBodyClass" v-if="visibleTaskStatusDate(task)">
              {{ getStatusChangedDate(task) }}
            </div>
          </v-col>

          <!-- Task Detail Info (Name,assignee,priority,due) -->
          <v-col cols="12" md="7">
            <div :class="columnHeaderClass">
              {{ getTaskName(task) }}
            </div>
            <div>
              <span :class="columnLabelClass">Assignee: </span>
              <span :class="columnBodyClass">
                {{ getTaskAssignee(task) }}
              </span>
            </div>
            <div>
              <span :class="columnLabelClass">Priority: </span>
              <span :class="priorityTextClass(task)">
                {{ getTaskPriority(task) }}
              </span>
            </div>
            <div v-if="visibleDuration(task)">
              <span :class="columnLabelClass">Duration: </span>
              <span :class="columnBodyClass">
                {{ getTaskDuration(task) }}
              </span>
            </div>
            <div v-if="visibleTaskDue(task)">
              <span :class="columnLabelClass">Due: </span>
              <span :class="dueDateTextClass(task)">
                {{ getTaskDueDate(task) }}
              </span>
            </div>
            <div v-if="visibleTaskOverdue(task)">
              <span :class="overdueTextClass(task)">Overdue!</span>
            </div>
            <div v-if="visibleMilestone(task)">
              <span :class="columnLabelClass">Milestone!</span>
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>

      <!-- now -->
      <timeline-item-now></timeline-item-now>
    </v-timeline>

    <project-task-data-dialog
      :project="project"
      :task="selectedTask"
      :visible="visibleDialogTaskData"
      @close="closeTaskDataDialog"
    >
    </project-task-data-dialog>

    <!-- Show Report Dialog -->
    <project-detailed-report-dialog
      :project="project"
      :visible="visibleDialogProjectExport"
      @close="closeDialogExportProject"
    ></project-detailed-report-dialog>

    <base-context-menu
      ref="menu"
      :contextMenuItems="projectTimelineMenuItems"
      :right-clicked-item="selectedTask"
      @onContextAction="performAction"
    >
    </base-context-menu>
  </div>
</template>

<script>
//mixin
import { timelineTaskMixin } from "@/mixins/shared/timeline/timelineTaskMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export default {
  name: "ProjectTasksTimeline",
  mixins: [timelineTaskMixin, userMixin],
  components: {
    TimelineItemNow: () =>
      import("@/components/shared/core/timeline/TimelineItemNow"),
    ProjectTaskDataDialog: () =>
      import("@/components/project/dialogs/ProjectTaskDataDialog.vue"),
    BaseContextMenu: () => import("@/components/shared/base/BaseContextMenu"),
    ProjectDetailedReportDialog: () =>
      import("@/components/project/dialogs/ProjectDetailedReportDialog.vue")
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}}
     */
    project: undefined,
    /**
     * project's record
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[]}}
     */
    record: undefined
  },
  computed: {
    /**
     * user Tasks
     * @return {{taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}[]}
     */
    userTasks() {
      return this.project?.userTasks ?? [];
    }
  }
};
</script>

<style scoped></style>
